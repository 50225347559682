.table-row-selected {
  background-color: var(--pf-global--palette--blue-50);
}

.mc-table [type="checkbox"] {
  cursor: pointer;
}

.mc-pointer {
  cursor: pointer;
}
