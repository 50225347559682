.mc-model {
  position: absolute;
  top: 25px;
  font-size: 14px;
  footer {
    display: block;
    button {
      float: right;
      margin-left: 10px;
    }
  }
}
