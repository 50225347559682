@use "theme";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  //   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
// }

#root {
  height: 100%; // Height 100% fixes navbar menu and page to fit full height

  --pf-global--spacer--form-element: 0.2rem; // default: 0.375rem
  --pf-global--gutter: 1rem; // default: 1rem
  //--pf-c-form-control--Height: none;
}

/* Workaround for chrome border feature,
https://support.google.com/chrome/thread/48974735?hl=en

input,
button {
  outline: none;
}
*/

/* workaround for borders disappear 
issue: https://github.com/patternfly/patternfly-react/issues/5650
permanent solution: should be fixed "OptimizeCSSAssetsPlugin"
until the permanent solution added in MyController web console,
this workaround will resolve the issue
*/
// removed this workaround as on     "@patternfly/react-core": "^4.264.0" update
// .pf-c-select__toggle:before {
//   border-left: var(--pf-global--BorderWidth--sm) solid var(--pf-c-select__toggle--before--BorderLeftColor);
//   border-bottom: var(--pf-global--BorderWidth--sm) solid var(--pf-c-select__toggle--before--BorderBottomColor);
//   border-right: var(--pf-global--BorderWidth--sm) solid var(--pf-c-select__toggle--before--BorderRightColor);
//   border-top: var(--pf-global--BorderWidth--sm) solid var(--pf-c-select__toggle--before--BorderTopColor);
// }

// update z-index of time selector dropdown
// default: --pf-c-select__menu--ZIndex
.pf-c-date-picker {
  z-index: 101;
}

// workaround to fix overflow of menu items in the modal
// Original issue: https://github.com/mycontroller-org/web-console/issues/8
.pf-c-modal-box {
  .pf-c-modal-box__body {
    .pf-c-select {
      .pf-c-select__menu {
        position: relative;
      }
    }
  }
}
