.auto-resize {
  width: 100%;
  // height: 100%; // height will not work as it is a dynamic panel
}

.image-field {
  .divider {
    padding-top: 5px;
  }

  .name {
    padding-left: 0px;
  }

  .value {
    color: var(--pf-global--palette--black-850);
    font-size: 16px;
  }

  .value-timestamp {
    color: var(--pf-global--palette--black-600);
    font-size: 14px;
    line-height: 0px;
  }
}
