.mc-about {
  .pf-c-about-modal-box__brand-image {
    height: 3.7rem;
  }
  .pf-c-about-modal-box__brand {
    padding-bottom: 5px;
  }
  .pf-c-about-modal-box__content{
    margin-top: 0px;
  }
}