.mc-utilization-panel-item {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .utilization-chart {
    margin: 2px;
  }

  .gauge-value-timestamp {
    color: var(--mc-timestamp-color);
    font-size: 14px;
  }

  // Spark line chart css
  .mc-spark-chart {
    .title {
      color: var(--mc-widget-utilization-title-color);
      font-size: 16px;
    }
    .value {
      color: var(--mc-widget-utilization-value-color);
      font-size: 44px;
      line-height: 44px;
    }
    .value-unit {
      color: var(--mc-widget-utilization-unit-color);
      font-size: 22px;
      line-height: 0px;
    }
    .value-timestamp {
      color: var(--mc-timestamp-color);
      font-size: 14px;
      line-height: 0px;
    }
    .metric-function {
      color: var(--mc-widget-utilization-function-color);
      font-size: 12px;
      position: fixed;
      bottom: 0;
      padding-bottom: 5px;
    }
    .no-metric-data {
      font-size: 14px;
      position: fixed;
      bottom: 0;
      padding-bottom: 2px;
    }
    .metrics-chart {
      max-height: inherit;
      left: 0;
      position: fixed;
      bottom: 0;
    }
  }
}
