.mc-iframe {
  .pf-c-modal-box__body {
    padding: 4px;
    padding-bottom: 0px;
    margin-bottom: 4px;
    iframe {
      height: 90vh;
    }
  }
}
