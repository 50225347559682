.mc-slider {
  .slider {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .badge {
    min-width: 37px;
    margin-left: 12px;
  }
}
