.mc-color-box {
  height: 30px;
  max-width: 100%;
  // background-color: #aaa;
  border-radius: 2px;
  cursor: pointer;
  
}

.mc-color-circle-picker {
  width: 300px !important;
}
