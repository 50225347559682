.mc-utilization-panel-item {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .utilization-chart {
    margin: 2px;
  }

  .gauge-value-timestamp {
    color: var(--mc-timestamp-color);
    font-size: 14px;
  }
}
