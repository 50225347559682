.mc-utilization-panel-item {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .utilization-chart {
    margin: 2px;
  }

  .gauge-value-timestamp {
    color: var(--mc-timestamp-color);
    font-size: 14px;
  }

  // table
  .mc-utilization-table {
    &.ut-table {
      cursor: auto;
    }

    &.pf-c-table thead > tr > *,
    &.pf-c-table tbody > tr > * {
      vertical-align: bottom;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    &.pf-c-table tbody > tr > *:last-child {
      padding-right: 0px;
    }
    .table-progress-bar {
      --pf-c-progress__bar--Height: 1.2rem;
    }
  }

}
