.ws-react-c-topology {
  height: 85vh;
  .pf-l-stack {
    margin: -9px;
    width: calc(100% + 18px);
  }
  .project-toolbar .pf-c-dropdown > button {
    justify-content: space-between;
    width: 150px;
  }
  .topology-view-filter-dropdown > button {
    justify-content: space-between;
    width: 130px;
  }
  .pf-topology-side-bar {
    padding: 10px;
  }
}
