.key-value-map {
  padding: 2px;
  margin-bottom: 1px;
  /* border: 1px solid red; */
  /* background-color: #f2f7ff; */
  .key {
    display: inline-block;
    color: #0303038c;
    min-width: 150px;
    padding-right: 5px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.label-collection {
  overflow: hidden;
}

.label-pair {
  margin: 3px 2px 2px;
  max-width: 100%;
  float: left;
  display: block;

  .label {
    min-width: 50px;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }

  .label-key {
    --pf-c-label--BorderRadius: 0 2px 2px 0;
    --pf-c-label__content--before--BorderColor: #bee1f4;
    --pf-c-label--BackgroundColor: #bee1f4;
    color: #00659c;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 0;
  }

  .label-value {
    --pf-c-label--BorderRadius: 0 2px 2px 0;
    --pf-c-label__content--before--BorderColor: #7dc3e8;
    --pf-c-label--BackgroundColor: #7dc3e8;
    color: #fff;
    padding-top: 4px;

    padding-bottom: 4px;
    margin-left: 0;
  }
}
